const routes = {
    mainRoute: 'onesync',
    preSignIn: 'signin',
    signIn: 'store',
    // forgotPassword: 'forgot-password',
    // resetPassword: 'reset-password',
    // notAuthorize: 'not-authorize',
    containers: {
        home: 'home',
        inventory: 'inventory',
        deliveries: 'deliveries',
        payouts: 'payouts',
        settings: 'settings',
    },
    inventory: {
        inventory: {
            name: 'inventory',
            add: 'add-inventory',
            edit: 'edit-inventory',
            view: 'view-inventory'
        },
    }
};

export default routes;