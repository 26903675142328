import React, { useEffect } from "react";
import asyncRoute from './helpers/hoc/asyncRoute';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { Layout, theme, notification } from 'antd';
import { get, isEqual, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import routes from './routes';


// import Theme from './containers';

const Loader = asyncRoute(() => import('./components/Loader'));
const Theme = asyncRoute(() => import('./containers'));
const Signin = asyncRoute(() => import('./containers/Authentication/Signin'));
const PostSignin = asyncRoute(() => import('./containers/Authentication/PreSignin'));


const App = () => {
  const Navigate = useNavigate();

  const showSpinner = useSelector((state) => state.app.showFullSpinner);
  // const showSpinner = false;
  const isAuthenticated = JSON.parse(localStorage.getItem('AuthData'));
  const PreSignInData = JSON.parse(localStorage.getItem('PreSignInData'));
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message) => {
    api[type]({
      message
      // description:
    });
  };
  // THIS IS FOR MAKING DYNAMIC ROUTING WHERE THE USER WILL NOT FALL IN BETWEEN THE ROUTES AND SEE THE PAGE WHERE ROUTE NOT FOUND
  //   const paths = ['/','/onesync', '/onesync/settings/', '/onesync/settings/user', '/onesync/settings/user/'];

  // useEffect(() => {
  //     const currentPath = window.location.pathname;
  //     if (paths.includes(currentPath)) {
  //         Navigate('user/list');
  //     }
  // }, []);

  useEffect(() => {
    if (isEmpty(isAuthenticated) && !isEmpty(PreSignInData)) {
      Navigate(`${routes.signIn}`)
    } else if (isEmpty(isAuthenticated) || window.location.pathname === '/' || window.location.pathname === '/onesync' || window.location.pathname === '/onesync/') {
      Navigate(`${routes.preSignIn}`)
    }
  }, [])

  return (
    <>
      {showSpinner && <Loader />}
      {contextHolder}
      <Routes>
        <Route path={`/${routes.preSignIn}`} element={<Signin blue10={""} openNotificationWithIcon={openNotificationWithIcon} />} />
        <Route path={`/${routes.signIn}`} element={<PostSignin blue10={""} openNotificationWithIcon={openNotificationWithIcon} />} />
        {
          !isEmpty(isAuthenticated) &&
          <Route path={`/${routes.mainRoute}/*`} element={<Theme blue10={""} openNotificationWithIcon={openNotificationWithIcon} />} />
        }
      </Routes>
    </>
  )
}

export default App