import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';

const contentStyle = {
  padding: 50,
  borderRadius: 4,
};


const LoadingComponent = () => {
  return <Spin tip="Loading" style={{
    zIndex: 1110,
    top: '50%',
    left: '50%',
    position: 'fixed',
  }} />
}; // Simple loading component

const asyncRoute = (getComponent, props) => {
  const LazyComponent = lazy(() => getComponent()); // Create a lazy-loaded component

  // No need for explicit preloading within asyncRoute
  // (Suspense handles loading when the component is rendered)

  return (componentProps) => (
    <Suspense fallback={<LoadingComponent />}>
      <LazyComponent {...componentProps} {...props} />
    </Suspense>
  );
};

export default asyncRoute;
