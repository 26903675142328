
export const LOGOUT = 'LOGOUT';

export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';

export const END_AJAX_CALL = 'END_AJAX_CALL';

export const BEGIN_FULLSCREEN_AJAX_CALL = 'BEGIN_FULLSCREEN_AJAX_CALL';

export const END_FULLSCREEN_AJAX_CALL = 'END_FULLSCREEN_AJAX_CALL';

export const PRE_SIGN_IN_SUCCESS = 'PRE_SIGN_IN_SUCCESS';

export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';

export const USER_LIST_ALL = 'USER_LIST_ALL';

export const ADD_USER = 'ADD_USER';

export const USER_BY_ID = 'USER_BY_ID';

export const INVENTORY_LIST_ALL = 'INVENTORY_LIST_ALL';

export const ADD_TEMPLATE = 'ADD_TEMPLATE';

export const TEMPLATE_LIST_ALL = 'TEMPLATE_LIST_ALL';

export const TEMPLATE_BY_ID = 'TEMPLATE_BY_ID';

export const APPROVAL_LIST_BY_STATUS = 'APPROVAL_LIST_BY_STATUS';

export const APPROVAL_LIST_BY_ID = 'APPROVAL_LIST_BY_ID';

export const LOCATION_LIST_ALL = 'LOCATION_LIST_ALL';

export const PRODUCT_CATALOG_LIST_ALL = 'PRODUCT_CATALOG_LIST_ALL';

export const PRODUCT_LANDING_DETAILS_ALL = 'PRODUCT_LANDING_DETAILS_ALL';
