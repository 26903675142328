
import {
    LOGOUT,
    ADD_USER,
    APPROVAL_LIST_BY_STATUS,
    BEGIN_AJAX_CALL,
    END_AJAX_CALL,
    BEGIN_FULLSCREEN_AJAX_CALL,
    END_FULLSCREEN_AJAX_CALL,
    INVENTORY_LIST_ALL,
    LOCATION_LIST_ALL,
    PRE_SIGN_IN_SUCCESS,
    PRODUCT_CATALOG_LIST_ALL,
    PRODUCT_LANDING_DETAILS_ALL,
    TEMPLATE_BY_ID,
    TEMPLATE_LIST_ALL,
    USER_LIST_ALL,

}
    from '../constants/actionType';

const app = (state = {}, action) => {
    switch (action.type) {
        case BEGIN_AJAX_CALL:
        case END_AJAX_CALL:
            const { showSpinner } = action;
            return {
                ...state,
                showSpinner
            };
        case BEGIN_FULLSCREEN_AJAX_CALL:
        case END_FULLSCREEN_AJAX_CALL:
            const { showFullSpinner } = action;
            return {
                ...state,
                showFullSpinner
            };

        case LOGOUT:
            return {

            }
        case PRE_SIGN_IN_SUCCESS:
            const { preSignInData } = action;
            return {
                ...state,
                preSignInData,
            }
        case USER_LIST_ALL:
            const { userListAll } = action;
            return {
                ...state,
                userListAll
            }
        case ADD_USER:
            const { addUser } = action;
            return {
                ...state,
                addUser,
            }
        case INVENTORY_LIST_ALL:
            const { inventoryListAll } = action;
            return {
                ...state,
                inventoryListAll,
            }
        case TEMPLATE_LIST_ALL:
            const { templateListAll } = action;
            return {
                ...state,
                templateListAll,
            }
        case TEMPLATE_BY_ID:
            const { templateById } = action;
            return {
                ...state,
                templateById,
            }
        case APPROVAL_LIST_BY_STATUS:
            const { approvalListByStatus } = action;
            return {
                ...state,
                approvalListByStatus,
            }
        case LOCATION_LIST_ALL:
            const { locationListAll, } = action;
            return {
                ...state,
                locationListAll,
            }
        case PRODUCT_CATALOG_LIST_ALL:
            const { productCatalogListAll } = action;
            return {
                ...state,
                productCatalogListAll
            }
        case PRODUCT_LANDING_DETAILS_ALL:
            const { productLandingDetailsAll } = action;
            return {
                ...state,
                productLandingDetailsAll,
            }
        default:
            return state;
    }
};

export default app;