// create store / configure redux store

import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
// import storageSession from 'redux-persist/lib/storage/session';
// import {
//   persistStore,
//   persistReducer
// } from 'redux-persist';

import reducer from './../reducers';

// const persistConfig = {
//   key: 'app',
//   storage: storageSession,
//   // blacklist: ['app'],
//   // whitelist: ['configurationData'] // which reducer want to store
// }

// const persistedReducer = persistReducer(persistConfig, reducer);

// const configureStore = () => {
//   const store = createStore(
//     persistedReducer,
//     composeWithDevTools(applyMiddleware(thunk)),
//   );
//   const persistor = persistStore(store);

//   return {
//     store,
//     persistor,
//   };
// };

// const persistedReducer = persistReducer(persistConfig, reducer);

const configureStore = () => {
  const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(thunk)),
  );
  return { store };
};

export default configureStore();